import React, { useEffect, useState } from "react"
import Layout from "../components/layout-2"
import { Amplify, API, Auth, graphqlOperation, Hub } from "aws-amplify"
import awsconfic from "../aws-exports"
import { withAuthenticator } from "@aws-amplify/ui-react"
import "@aws-amplify/ui-react/styles.css"
import { settings } from "../shared"
import { getPersonBudgets } from "../utils/budget-queries"
import YnabAccount from "../components/ynab-account"
import LoadingAnimation from "../components/loading-animation"
import AccountSteps from "../components/account-steps"
import { getGocardlessInit } from "../graphql/gocardless"
import { Link } from "gatsby"
import { GoCardlessAuthorization } from "../types/gocardless-authorization"

Amplify.configure(awsconfic)

interface Budget {
  name?: string
}

interface budgetC {
  name: Budget
}

const OwnConnections = ({ signOut, user, _serverData, location }) => {
  const [userLoggedIn, setUserLoggedIn] = useState(!!user?.username)
  const [initFlow, setInitFlow] = useState<GoCardlessAuthorization>()
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event } }) => {
      if (event === "signIn") {
        setUserLoggedIn(true)
      }
    })

  })
  /*
  useEffect(() => {
    if (user) {
      console.log("user", user.username)
      getGocardlessInit()
        .then(res => setInitFlow(res))
    }
  }, [user])

  console.log("initiation", JSON.stringify(initFlow))
*/

  return (
    <Layout path="/subscribe" userLoggedIn={userLoggedIn}>
      <div className="h-96">
        <div className="items-center mx-20">
          <h2 className="text-center font-semibold text-2xl text-gray-700">Welcome to budgetsyncer</h2>
          <p className="mt-4 text-gray-700 text-center">Great news! You're all set to start using Budgetsyncer and dive into a smoother financial journey. If you have any questions or need assistance, feel free to drop me a line at admin@budgetsyncer.com. Happy budgeting!</p>
        </div>

          <div className="flex justify-center items-center pt-10">
            <Link className="bg-lime-600 hover:bg-lime-400 text-white py-2 px-4 rounded" to={'/own-connections'}>Go to my connections</Link>
          </div>

      </div>
    </Layout>
  )
}

export default withAuthenticator(OwnConnections)
